import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Observable, delay, of } from 'rxjs'
import { ConnectionService } from '@agroone-front/shared'

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  constructor(private connectionService: ConnectionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.connectionService.connected) {
      const offlineResponse: HttpResponse<{ status: number; body: string }> = new HttpResponse({
        status: 0,
        body: null,
      })
      return of(offlineResponse).pipe(delay(1000))
    }

    return next.handle(request)
  }
}
