<ng-container *ngIf="datasource">
  <input
    #input
    type="text"
    matInput
    [matAutocomplete]="autoValue"
    [formControl]="tmpformCtrl"
    aoKeyboard
    [ngClass]="{
      'required-error-auto': customStyle && !isValid,
      'pass-required-auto': customStyle && isValid
    }"
    [id]="'ao-select-input-' + uniqueId"
  />
  <mat-autocomplete
    #autoValue="matAutocomplete"
    [displayWith]="display.bind(this)"
    (optionSelected)="input.blur(); selectOption.emit($event?.option?.value)"
    (opened)="checkOverlay()"
    (closed)="onCloseSelect()"
  >
    <div class="viewport">
      <!-- At least one option must exist to open the autocomplete overlay -->
      <mat-option *ngIf="optional" [value]="undefined"></mat-option>

      <ng-container *ngIf="filteredMostUsedItemsList?.length">
        <div class="frequent-items">
          {{ 'AUTOCOMPLETE.FREQUENTLY_USED_ITEMS' | translate }}
        </div>
        <mat-option *ngFor="let item of filteredMostUsedItemsList" [value]="item">
          <span [innerHTML]="display(item) | highlight : tmpformCtrl?.value"></span>
        </mat-option>
        <div class="separator">---------------</div>
      </ng-container>

      <mat-option *uiScroll="let option of datasource" [value]="option">
        <span [innerHTML]="display(option) | highlight : tmpformCtrl?.value"></span>
      </mat-option>
    </div>
  </mat-autocomplete>
</ng-container>
