import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ShapefileType } from '@agroone-app/shared/map/models/shapefileType.enum'
import { environment } from '@agroone-app/env/environment'

@Injectable({
  providedIn: 'root',
})
export class GeolocationHttpService {
  constructor(private http: HttpClient) {}

  public getShapefile(type: ShapefileType, ids: number[]): Observable<string> {
    return this.http
      .post(`${environment.newApiUrl}/geolocation/shapefile`, {
        ids,
        type,
      })
      .pipe(map((res: { base64: string }) => res.base64))
  }
}
