import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IsGrantedDirective } from './directives/is-granted.directive'
import { IsOfflineDirective } from '@agroone-front/shared'
import { EmailToNamePipe } from './pipes/email-to-name.pipe'

@NgModule({
  imports: [CommonModule, IsOfflineDirective],
  declarations: [EmailToNamePipe, IsGrantedDirective],
  exports: [EmailToNamePipe, IsGrantedDirective, IsOfflineDirective],
})
export class UserModule {}
