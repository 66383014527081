import { PermissionService } from '@agroone-app/core/user/permission.service'
import { environment } from '@agroone-app/env/environment'
import { GrowerState } from '@agroone-app/scene/crop-management/grower/store/reducers'
import {
  selectGrowerById,
  selectGrowers,
} from '@agroone-app/scene/crop-management/grower/store/selectors/grower.selectors'
import { ConnectionService } from '@agroone-front/shared'
import {
  Grower,
  GrowerCooperativesDto,
  GrowerCsrCertification,
  MsSqlPaginatedData,
  SaveGrower,
  UserPermissions,
} from '@agroone/entities'
import { HttpClient, HttpParams } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { catchError, debounceTime, map, mergeMap, Observable, of, switchMap, take } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class GrowerService {
  private growerStore = inject(Store<GrowerState>)
  private http = inject(HttpClient)
  private permissionService = inject(PermissionService)
  private connectionService = inject(ConnectionService)
  private growerApiUrl = `${environment.newApiUrl}/growers`

  public getCooperatives(): Observable<GrowerCooperativesDto[]> {
    return this.http
      .get<MsSqlPaginatedData<GrowerCooperativesDto>>(`${environment.newApiUrl}/growers/cooperatives`)
      .pipe(map((v: MsSqlPaginatedData<GrowerCooperativesDto>) => v.data))
  }

  public getMine(): Observable<Grower> {
    return this.http.get<Grower>(`${this.growerApiUrl}/mine`)
  }

  /**
   * Get grower by id and region
   * first from the  grower state, else from the remote serve
   *
   * @param id
   * @param region
   * @returns
   */
  public get(id: number, region: string): Observable<Grower> {
    let params: HttpParams = new HttpParams()
    params = params.append('region', region)

    const options: {
      params: HttpParams
    } = {
      params,
    }

    return this.growerStore.select(selectGrowerById(id)).pipe(
      take(1),
      debounceTime(200),
      mergeMap((state) => {
        if (state) {
          return of(state)
        }
        return this.http.get<Grower>(`${this.growerApiUrl}/${id}`, options).pipe(
          map((grower: Grower) => new Grower(grower)),
          catchError((error) => {
            return of(error)
          })
        )
      })
    )
  }

  /**
   * Return all growers
   *
   * @param onlyActive
   * @returns
   */
  public getAll(onlyActive = true): Observable<Grower[]> {
    if (!this.connectionService.connected) {
      return this.growerStore.select(selectGrowers)
    }

    const options: { params: { filter?: string } } = {
      params: {},
    }
    const filters: string[] = []
    if (onlyActive) {
      filters.push('active=1')
    }
    const filter = filters.join(',')
    if (filter) {
      options.params.filter = filter
    }
    return of(
      this.permissionService.isGranted([
        UserPermissions.GROWERS_VIEW,
        // UserPermissions.THIRD_PARTY_APPLICATOR_TASK_EDIT,
        UserPermissions.WORKLIST_EDIT,
        UserPermissions.PLANTING_MODULE_ACCESS,
        UserPermissions.USER_EDIT,
      ])
    ).pipe(
      switchMap((hasPermission) => {
        if (hasPermission) {
          return this.http.get<MsSqlPaginatedData<Grower>>(this.growerApiUrl, options).pipe(
            map((paginatedGrowers) => {
              if (paginatedGrowers?.data?.length) {
                return paginatedGrowers.data
                  .sort((x, y) => x.growerName.localeCompare(y.growerName))
                  .map((g) => {
                    const grower = new Grower(g)
                    grower.contacts.sort((x, y) => x.priority - y.priority)
                    return grower
                  })
              }
              return paginatedGrowers?.data
            })
          )
        } else {
          return of([])
        }
      })
    )
  }

  /**
   * Save grower on create or update
   *
   * @param grower {SaveGrower}
   * @returns { Observable<Grower>}
   */
  public save(grower: SaveGrower): Observable<Grower> {
    if (grower.id) {
      return this.http.put<Grower>(this.growerApiUrl, grower)
    }

    return this.http.post<Grower>(this.growerApiUrl, grower)
  }

  public getAllGrowerCsrCertification(): Observable<GrowerCsrCertification[]> {
    return this.http.get<GrowerCsrCertification[]>(`${environment.newApiUrl}/csr/certifications`)
  }
}
