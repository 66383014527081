import packageInfo from '../../../../package.json'
import { AgrooneAppEnvironment, UserPermissions } from '@agroone/entities'

export const environment: AgrooneAppEnvironment = {
  production: true,
  envName: 'qlf',
  dynatraceScriptSrc: '',
  logLevel: 'INFO',
  serverLogLevel: 'WARN',
  version: packageInfo.version,
  newApiUrl: 'https://apiv2.cldqlf.bonduelle.com',
  baseUrl: '/api',
  versionUri: 'https://apiv2.cldqlf.bonduelle.com/version',
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
  harvestUrl: 'https://agropilot.cldqlf.bonduelle.com/',
  masterdataUrl: 'https://masterdata.cldqlf.bonduelle.com/',
  crops: '/crops',
  virtualCrops: '/virtual-crops',
  fields: '/fields',
  scoutings: '/scoutings',
  regions: '/regions',
  users: '/users',
  varieties: '/varieties',
  recommendations: '/recommendations',
  geolocation: '/geolocation',
  interventions: '/interventions',
  dashboard: '/dashboard',
  worklists: '/worklists',
  samples: '/samples',
  tpatasks: '/tpa-tasks',
  croptypeForecasts: '/croptype-forecasts',
  hypothesis: '/hypothesis',
  sowingPlannings: '/sowing-plannings',
  growerProductionAreas: '/grower-production-areas',
  offers: '/offers',
  countries: '/countries',
  csr: '/csr',
  csrConstants: '/csr-constants',
  constants: '/constants',
  croptypes: '/croptypes',
  isLocal: false,
  localEmail: 'localhost@bonduelle.com',
  headerColorStart: '#ffa726',
  headerColorEnd: '#ffcc80',
  remoteMonitoring: true,
  remoteMonitoringRatio: 1,
  menuItems: {
    geolocation: {
      icon: 'geolocation',
      link: '/crop-management/geolocation',
      disabled: false,
      permission: UserPermissions.GEOLOCATION_VIEW,
    },
    notifications: {
      icon: 'notifications',
      link: '/crop-management/notifications',
      disabled: true,
    },
    grower: {
      icon: 'grower',
      link: '/crop-management/grower',
      disabled: false,
      permission: UserPermissions.GROWERS_VIEW,
    },
    field: {
      icon: 'field-icon',
      link: '/crop-management/field',
      disabled: false,
      permission: UserPermissions.FIELDS_VIEW,
    },
    crop: {
      icon: 'crop',
      link: '/crop-management/crop',
      disabled: false,
      permission: UserPermissions.CROPS_VIEW,
    },
    dashboard: {
      icon: 'dashboard_original',
      link: '/crop-management/dashboard',
      disabled: false,
      permission: UserPermissions.DASHBOARD_VIEW,
    },
  },
}
