import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, search: string): string {
    if (typeof search !== 'string' || typeof value !== 'string') {
      return value
    }
    // Exclude all character that are not numbers, letters, single quote or space
    const sanitizedSearch: string = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    return value.replace(
      new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + sanitizedSearch + ')(?![^<>]*>)(?![^&;]+;)', 'gi'),
      '<span class="primary-lightest-background highlighted">$1</span>'
    )
  }
}
