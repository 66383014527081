import { Component, Inject, Injector, OnDestroy } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { LoggerService } from '@agroone-front/shared'
import { TranslateService } from '@ngx-translate/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { UserService } from './user/user.service'

/**
 * @deprecated
 * DON'T USE ANYMORE !!!
 * For destroyed use the @ngneat/until-destroy library already imported in the project
 * For the different services, import them directly in the constructor of your components
 * For the openToast use the function within the DialogService
 *
 */
@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  /**
   * Translate service
   * @deprecated
   */
  protected translate: TranslateService
  /**
   * @deprecated
   */
  protected logger: LoggerService
  /**
   * @deprecated
   */
  protected destroyed: Subject<void> = new Subject()
  /**
   * @deprecated
   */
  protected snackBar: MatSnackBar
  /**
   * @deprecated
   */
  protected userService: UserService

  constructor(@Inject(Injector) injector: Injector) {
    // Fetch the main injector.
    this.logger = injector.get(LoggerService)
    this.translate = injector.get(TranslateService)
    this.userService = injector.get(UserService)
    this.snackBar = injector.get(MatSnackBar)
  }

  /**
   * @deprecated
   */
  openToast(message: string, action?: string, durations: number = 2000): Observable<void> {
    return this.snackBar
      .open(message, action, {
        duration: durations,
      })
      .onAction()
  }

  /**
   * @deprecated
   */
  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
