import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, Injector, isDevMode, NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GlobalErrorHandler } from './core/message/services/error-handler.service'
import { AppInjector } from './core/app-injector'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BaseComponent } from './core/base.component'
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { DialogModule } from '@agroone-app/shared/dialog/dialog.module'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { LayoutModule } from './shared/layout/layout.module'
import { UserService } from './core/user/user.service'
import { ConstantService } from './shared/constant/constant.service'
import { SettingsService } from './shared/settings/settings.service'
import { INDEXDB_CONFIG } from './core/storage/indexdb.config'
import { RegionService } from './shared/region/services/region.service'
import { CustomDateAdapter } from './shared/form/CustomDateAdapter'
import { Platform } from '@angular/cdk/platform'
import { LanguageService } from './shared/languages/languages.service'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'
import { ReducerManager, Store, StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { ServiceWorkerModule } from '@angular/service-worker'
import { CropEffects } from './scene/crop-management/crop/store/effects/crop.effects'
import { idbReducer, IdbStorageService, loadingReducer, LoadingState, metaReducers } from '@agroone-app/state'
import { ScoutingEffects } from './scene/crop-management/crop/store/effects/scouting.effects'
import { RecommendationEffects } from './scene/crop-management/crop/store/effects/recommendation.effects'
import { InterventionEffects } from './scene/crop-management/crop/store/effects/intervention.effects'
import { FieldEffects } from './scene/crop-management/field/store/effects/field.effects'
import { ActivitiesEffects } from './scene/crop-management/crop/store/effects/activity.effects'
import { GrowerEffects } from './scene/crop-management/grower/store/effects/grower.effects'
import { GrowerState } from './scene/crop-management/grower/store/reducers'
import { CropService } from './shared/crop/services/crop.service'
import { init } from './app-init-factory'
import { OfflineEffects } from './scene/crop-management/crop/store/effects/offline.effects'
import {
  AuthService,
  ErrorInterceptor,
  LoggerService,
  LoginComponent,
  MonitoringService,
  NewVersionPopupComponent,
  SharedConstantService,
  SharedLogoutComponent,
  VersionService,
  WithCredentialsInterceptor,
} from '@agroone-front/shared'
import { OfflineInterceptor } from '@agroone-app/core/network/offline.interceptor'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { VarietyState } from './scene/crop-management/crop/store/reducers'
import { VarietyEffects } from './scene/crop-management/crop/store/effects/variety.effects'
import { SpecificationEffects } from './scene/crop-management/crop/store/effects/specification.effects'
import { SpecificationState } from './scene/crop-management/crop/store/reducers/specification.reducer'
import { TranslationsLoader } from '@agroone-app/translations.loader'

export const storeDevTools = !environment.production ? [StoreDevtoolsModule.instrument({ maxAge: 25 })] : []

export const DEFAULT = {
  dbName: 'default',
}

@NgModule({
  declarations: [AppComponent, BaseComponent],
  exports: [BaseComponent],
  imports: [
    LoginComponent,
    SharedLogoutComponent,
    BrowserModule,
    NewVersionPopupComponent,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
      },
    }),
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    DialogModule,
    LayoutModule,
    StoreModule.forRoot(
      { loading: loadingReducer },
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    // storeDevTools,
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      GrowerEffects,
      FieldEffects,
      CropEffects,
      ScoutingEffects,
      InterventionEffects,
      RecommendationEffects,
      ActivitiesEffects,
      OfflineEffects,
      VarietyEffects,
      SpecificationEffects,
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    // Initializers
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [
        SharedConstantService,
        SettingsService,
        RegionService,
        LanguageService,
        Store<GrowerState>,
        CropService,
        Store<LoadingState>,
        LoggerService,
        AuthService,
        UserService,
        Store<VarietyState>,
        Store<SpecificationState>,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: idbReducer,
      deps: [ReducerManager, IdbStorageService],
      multi: true,
    },

    // Error handling
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },

    // Dates
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform, UserService],
    },
    { provide: INDEXDB_CONFIG, useValue: DEFAULT },
    MonitoringService,
    LoggerService,
    AuthService,
    VersionService,
    {
      provide: SharedConstantService,
      useClass: ConstantService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector)
  }
}
