import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RequiredFieldDirective } from './required-field.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [RequiredFieldDirective],
  exports: [RequiredFieldDirective],
})
export class RequiredFieldModule {}
