import { DOCUMENT } from '@angular/common'
import { Directive, ElementRef, AfterViewInit, Input, Renderer2, Inject } from '@angular/core'

@Directive({
  selector: '[aoRequired]',
})
export class RequiredFieldDirective implements AfterViewInit {
  @Input() aoRequired?: '' | false

  private requiredElement: HTMLElement

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    this.init()
  }

  private init() {
    const element: any = this.el.nativeElement
    if (this.aoRequired !== false && !this.requiredElement) {
      this.requiredElement = this.document.createElement('span')
      this.renderer.addClass(this.requiredElement, 'ao-required')
      this.renderer.setProperty(this.requiredElement, 'innerHTML', '*')
      this.renderer.appendChild(element, this.requiredElement)
    }
  }
}
