import { Injectable } from '@angular/core'
import { environment } from '@agroone-app/env/environment'
import { BehaviorSubject, catchError, EMPTY, filter, forkJoin, map, mergeMap, Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { LoggerService } from '@agroone-front/shared'
import { UserService } from '@agroone-app/core/user/user.service'
import {
  CroptypeColor,
  ForecastDay,
  MsSqlPaginatedData,
  Region,
  Settings,
  StandCountsFactor,
  TargetLeave,
  UserDto,
  WeekFactor,
} from '@agroone/entities'
import { RegionService } from '../region/services/region.service'
import { getISOWeek, getYear } from 'date-fns'

import configureMeasurements, { allMeasures } from 'convert-units'

const convert = configureMeasurements(allMeasures)

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public cropTypeColors: CroptypeColor[]
  public standCountsFactors: StandCountsFactor[]
  public weekFactors: WeekFactor[]
  public targetLeaves: TargetLeave[]

  public get forecastDays(): ForecastDay[] {
    return this.forecastDaysSubject.getValue()
  }

  private forecastDaysSubject: BehaviorSubject<ForecastDay[]> = new BehaviorSubject<ForecastDay[]>(null)

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private userService: UserService,
    private regionService: RegionService
  ) {}

  init(regions: Region[]): Promise<void> {
    return new Promise<void>((resolve) => {
      this.userService.user
        .pipe(
          mergeMap((user: UserDto) => (user ? this.getAll(user, regions) : of(new Settings()))),
          filter(Boolean),
          catchError((error) => {
            this.logger.error('Settings loading error', JSON.stringify(error))
            return of(EMPTY)
          })
        )
        .subscribe(
          ([croptypeColors, weekFactors, targetLeaves, standCountsFactors]: [
            CroptypeColor[],
            WeekFactor[],
            TargetLeave[],
            StandCountsFactor[]
          ]) => {
            this.cropTypeColors = croptypeColors
            this.weekFactors = weekFactors
            this.targetLeaves = targetLeaves
            this.standCountsFactors = standCountsFactors
            this.logger.log('Settings initialized')
            resolve()
          }
        )
    })
  }

  public getCurrentCampaign(): string {
    const weekNumber = getISOWeek(new Date())
    let year = getYear(new Date())

    const letterCampaign = this.getCampaignLetter(weekNumber)
    if (weekNumber >= 44 && weekNumber <= 52) {
      year += 1
    }
    const campaignYearTwoDigits = year.toString().substring(2)

    return `${campaignYearTwoDigits}${letterCampaign}`
  }

  public getAll(
    user: UserDto,
    regions: Region[]
  ): Observable<[CroptypeColor[], WeekFactor[], TargetLeave[], StandCountsFactor[]]> {
    const regionId = regions?.find((r) => r.name === user.regionName)?.id
    const croptypeColors = this.http
      .get<MsSqlPaginatedData<CroptypeColor>>(`${environment.newApiUrl}/settings/croptype-colors`)
      .pipe(map((v) => v.data))
    const weekFactors = this.http
      .get<MsSqlPaginatedData<WeekFactor>>(`${environment.newApiUrl}/settings/week-factors?filter=regionId=${regionId}`)
      .pipe(map((v) => v.data))
    const targetLeaves = this.http
      .get<MsSqlPaginatedData<TargetLeave>>(
        `${environment.newApiUrl}/settings/target-leaves?filter=regionId=${regionId}`
      )
      .pipe(map((v) => v.data))
    const standCountsFactors = this.http
      .get<MsSqlPaginatedData<StandCountsFactor>>(
        `${environment.newApiUrl}/settings/stand-counts?filter=regionId=${regionId}`
      )
      .pipe(map((v) => v.data))
    return forkJoin([croptypeColors, weekFactors, targetLeaves, standCountsFactors])
  }

  public getAllForecastDays(): Observable<ForecastDay[]> {
    return this.http
      .get<MsSqlPaginatedData<ForecastDay>>(`${environment.newApiUrl}/settings/forecast-days`)
      .pipe(map((v) => v.data))
  }

  public getForecastDaysFromUsersRegions(): Observable<ForecastDay[]> {
    let forecastDays: ForecastDay[]
    return this.getAllForecastDays().pipe(
      mergeMap((s: ForecastDay[]) => {
        forecastDays = s
        return of(this.regionService.allRegion)
      }),
      map((regions: Region[]) => {
        // Get the businessUnits corresponding to the availableRegions of the user
        const businessUnits = [
          ...new Set(
            regions
              .filter((region) => this.userService.currentUser.availableRegions.includes(region.name))
              .map((r) => r.businessUnit)
              .filter(Boolean)
          ),
        ]
        const forecastDaysFromUsersRegions = forecastDays.filter(
          (f: ForecastDay) =>
            this.userService.currentUser.availableRegions.includes(f.region) || businessUnits.includes(f.region as any)
        )
        this.forecastDaysSubject.next(forecastDaysFromUsersRegions)
        return forecastDaysFromUsersRegions
      })
    )
  }

  public saveForecastDays(regionId: number, numberOfDays: number, id?: number): Observable<ForecastDay> {
    if (!regionId || !numberOfDays) {
      return
    }
    if (id) {
      return this.http.put<ForecastDay>(`${environment.newApiUrl}/settings/forecast-days`, {
        id,
        regionId,
        numberOfDays,
      })
    } else {
      return this.http.post<ForecastDay>(`${environment.newApiUrl}/settings/forecast-days`, { regionId, numberOfDays })
    }
  }

  public delete(id: number): Observable<ForecastDay> {
    return this.http.delete<ForecastDay>(`${environment.newApiUrl}/settings/forecast-days/${id}`)
  }

  public getStandCountsFactor(cropType: string, bedWidth: number, bedWidthUnit: string, seedLines: number): number {
    if (!cropType || !bedWidth || !bedWidthUnit || !seedLines) {
      return
    }
    return this.standCountsFactors.find((standCountsFactorItem: StandCountsFactor) => {
      let bedWidthValue = standCountsFactorItem.bedWidth
      if (standCountsFactorItem.bedWidthUnit !== bedWidthUnit) {
        bedWidthValue = convert(bedWidthValue as number)
          .from(standCountsFactorItem.bedWidthUnit as any)
          .to(bedWidthUnit as any)
      }

      return (
        cropType === standCountsFactorItem.croptype &&
        seedLines === standCountsFactorItem.seedLines &&
        bedWidthValue === bedWidth
      )
    })?.standCountsFactor
  }

  public getTargetLeaves(cropType: string): number {
    return this.targetLeaves.find((targetLeavesItem: TargetLeave) => cropType === targetLeavesItem.croptype)
      ?.targetLeaves
  }

  public getWeekFactor(cropType: string, interventionWeek: number, maturityWeek: number): number {
    return (
      this.weekFactors.find(
        (weekFactorItem: WeekFactor) =>
          cropType === weekFactorItem.croptype &&
          maturityWeek === weekFactorItem.maturityWeek &&
          interventionWeek === weekFactorItem.interventionWeek
      )?.weekFactor || 0
    )
  }

  private getCampaignLetter(weekNumber: number) {
    return weekNumber >= 18 && weekNumber <= 43 ? 'E' : 'H'
  }
}
