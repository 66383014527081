import {
  LoadingState,
  selectLoadingCrops,
  selectLoadingGrowers,
  selectLoadingSpecifications,
  selectLoadingVarieties,
} from '@agroone-app/state'
import { AuthService, LoggerService, SharedConstantService } from '@agroone-front/shared'
import { UserDto } from '@agroone/entities'
import { Store } from '@ngrx/store'
import weloopai from '@weloopai/sdk'
import { combineLatest, filter, switchMap, tap } from 'rxjs'
import { environment } from '../environments/environment'
import { UserService } from './core/user/user.service'
import { loadSpecifications } from './scene/crop-management/crop/store/actions/specification.actions'
import { loadVarieties } from './scene/crop-management/crop/store/actions/variety.actions'
import { VarietyState } from './scene/crop-management/crop/store/reducers'
import { SpecificationState } from './scene/crop-management/crop/store/reducers/specification.reducer'
import { loadGrowers } from './scene/crop-management/grower/store/actions/grower.actions'
import { GrowerState } from './scene/crop-management/grower/store/reducers'
import { CropService } from './shared/crop/services/crop.service'
import { LanguageService } from './shared/languages/languages.service'
import { RegionService } from './shared/region/services/region.service'
import { SettingsService } from './shared/settings/settings.service'

export const init =
  (
    constantService: SharedConstantService,
    settingsService: SettingsService,
    regionService: RegionService,
    languageService: LanguageService,
    growerStore: Store<GrowerState>,
    cropService: CropService,
    loadingStore: Store<LoadingState>,
    logger: LoggerService,
    authService: AuthService,
    userService: UserService,
    varietyStore: Store<VarietyState>,
    specificationStore: Store<SpecificationState>
  ) =>
  async () => {
    loadExternalScript(environment.dynatraceScriptSrc)
    let user: UserDto
    // todo: remove the initialization lines below after migrating them to libs/
    logger.init(environment.logLevel, environment.serverLogLevel, environment.isLocal)
    authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.newApiUrl)

    // Skip app init when logout
    if (document.location.pathname === '/logout') {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      combineLatest([userService.init(), regionService.init()])
        .pipe(
          switchMap((res) => {
            user = res[0]
            return combineLatest([constantService.init(), settingsService.init(res[1]), languageService.init()]).pipe(
              switchMap(() => regionService.activeRegion),
              tap(() => growerStore.dispatch(loadGrowers())),
              switchMap(() => loadingStore.select(selectLoadingGrowers)),
              filter((isLoadingGrowers) => !isLoadingGrowers),
              tap(() => logger.log('Growers data is loaded')),
              tap(() => cropService.dispatchLoadCrops()),
              switchMap(() => loadingStore.select(selectLoadingCrops)),
              filter((isLoadingCrops) => !isLoadingCrops),
              tap(() => logger.log('Crops data is loaded')),
              tap(() => varietyStore.dispatch(loadVarieties())),
              switchMap(() => loadingStore.select(selectLoadingVarieties)),
              filter((isLoadingVarieties) => !isLoadingVarieties),
              tap(() => logger.log('Varieties data is loaded')),
              tap(() => specificationStore.dispatch(loadSpecifications())),
              switchMap(() => loadingStore.select(selectLoadingSpecifications)),
              filter((isLoadingSpecifications) => !isLoadingSpecifications),
              tap(() => logger.log('Specifications data is loaded'))
            )
          })
        )
        .subscribe({
          next: () => {
            if (
              (user?.email?.endsWith('@bonduelle.com') || user?.email?.endsWith('@opoasi.it')) &&
              environment.envName === 'prd'
            ) {
              weloopai.init({
                key: '4a659440-6386-11ea-851c-03c00b0fdf8f',
              })
            }
            resolve(true)
          },
          error: (error) => {
            logger.error(error)
            resolve(false)
          },
        })
    })
  }

function loadExternalScript(scriptUrl: string): void {
  if (!scriptUrl) {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.src = scriptUrl
  scriptElement.type = 'text/javascript'
  document.body.appendChild(scriptElement)
}
