import { LoggerService, NewVersionPopupComponent } from '../'
import { Injectable } from '@angular/core'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter, map } from 'rxjs'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'

@Injectable()
export class VersionService {
  constructor(private updates: SwUpdate, private dialog: MatDialog, private loggerService: LoggerService) {
    // Check for updates during "visibilitychange" event
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkForUpdates()
      }
    })

    // Check for updates during application start
    this.checkForUpdates()

    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe(() => {
        this.dialog.open(NewVersionPopupComponent, {
          disableClose: true,
        })
      })
  }

  checkForUpdates() {
    if (!this.updates.isEnabled) {
      this.loggerService.log('SW not enabled')
      return
    }

    this.updates.checkForUpdate().then(() => {
      this.loggerService.log('Checked for updates')
    })
  }
}
