import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { RouterModule } from '@angular/router'

import { AgroOneSnackBarComponent } from './components/agroone-snack-bar.component'

@NgModule({
  imports: [CommonModule, RouterModule, MatSnackBarModule, MatIconModule],
  declarations: [AgroOneSnackBarComponent],
})
export class AgroOneSnackBarModule {}
