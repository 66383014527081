import { format, parse as DateFnsParse } from 'date-fns'
import { UserService } from '@agroone-app/core/user/user.service'
import { Inject, Injectable } from '@angular/core'
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'
import { Platform } from '@angular/cdk/platform'

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(@Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform, public userService: UserService) {
    super(matDateLocale, platform)
  }

  override parse(value: any): Date | null {
    const dateFormat: string = this.userService.currentUserDateFormat.format
    return DateFnsParse(value, dateFormat, new Date())
  }

  override format(date: Date, displayFormat: string): string {
    const dateFormat: string = this.userService.currentUserDateFormat.format || displayFormat
    return format(date, dateFormat)
  }
}
