import { SingleModuleAccessResolver } from '@agroone-app/core/user/single-module-access.resolver'
import { UserGuard } from '@agroone-app/core/user/user.guard'
import { AccessDeniedComponent, LoginComponent, SharedLogoutComponent } from '@agroone-front/shared'
import { UserPermissions } from '@agroone/entities'
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AppRoutingEnum } from '@agroone-app/app-routing.enum'

const routes: Routes = [
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'auth/login', component: LoginComponent },
  { path: AppRoutingEnum.LOGOUT, component: SharedLogoutComponent },
  {
    path: '/',
    resolve: [SingleModuleAccessResolver],
    loadChildren: () => import('./scene/hub/hub.module').then((m) => m.HubModule),
  },
  {
    path: '',
    resolve: [SingleModuleAccessResolver],
    loadChildren: () => import('./scene/hub/hub.module').then((m) => m.HubModule),
  },
  {
    path: AppRoutingEnum.CROP_MANAGEMENT,
    loadChildren: () => import('./scene/crop-management/crop-management.module').then((m) => m.CropManagmentModule),
    canActivate: [UserGuard],
    data: {
      permissions: [UserPermissions.CROP_MODULE_ACCESS],
    },
  },
  {
    path: 'user-management',
    loadChildren: () => import('./scene/user-management/user-management.module').then((m) => m.UserManagmentModule),
    canActivate: [UserGuard],
    data: {
      permissions: [UserPermissions.USER_MODULE_ACCESS],
    },
  },
  {
    path: 'planting-management',
    loadChildren: () =>
      import('./scene/planting-management/planting-management.module').then((m) => m.PlantingManagementModule),
    canActivate: [UserGuard],
    data: {
      permissions: [UserPermissions.PLANTING_MODULE_ACCESS],
    },
  },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
