<div class="version">
  <mat-dialog-content class="version-content">
    {{ 'DIALOG.UPDATE.MESSAGE' | translate }}
  </mat-dialog-content>

  <mat-dialog-actions class="version-actions">
    <button (click)="reload()" mat-flat-button>
      {{ 'DIALOG.UPDATE.RELOAD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
