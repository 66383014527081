import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { format, getHours, getMinutes, isBefore, set } from 'date-fns'
import { TranslateService } from '@ngx-translate/core'
import { FormElement } from '../models/form-element.model'
import { UserService } from '@agroone-app/core/user/user.service'
import { DateFormats } from '@agroone/dates'

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.sass'],
})
export class DatetimepickerComponent implements OnInit {
  @Input() formElement?: FormElement = new FormElement()
  @Input() control?: FormControl
  @Input() icon: string
  @Input() disabled?: boolean
  @Input() cancel?: boolean
  @Input() time?: boolean
  @Input() minDateAllowed?: string | Date
  @Input() maxDateAllowed?: string | Date
  @Input() isValid?: boolean = false
  @Input() customStyle?: boolean = false
  @Input() isDateRange?: boolean = false
  @Input() placeholder?: string = this.userService.currentUserDateFormat.format
  @Input() showTime?: boolean = false

  private _userDateFormatToPrimeNgDateFormat: Record<DateFormats, string> = {
    [DateFormats.YYYY_MM_DD]: 'yy-mm-dd',
    [DateFormats.DD_MM_YY]: 'dd/mm/y',
    [DateFormats.YY_MM_DD]: 'y-mm-dd',
  }

  dateFormatForCalendar = this._userDateFormatToPrimeNgDateFormat[this.userService.currentUserDateFormat.format]

  public timeForm: FormGroup

  get inputHour(): AbstractControl {
    return this.timeForm.get('inputHour')
  }

  get inputMinute(): AbstractControl {
    return this.timeForm.get('inputMinute')
  }

  public hours = [...Array(24).keys()].map((x) => String(x).padStart(2, '0'))
  public minutes = [...Array(60).keys()].map((x) => String(x).padStart(2, '0'))

  constructor(public translate: TranslateService, public userService: UserService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.convertMinMaxDateAllowed()
    // PRIME NG
    if (this.control?.value) {
      if (this.isDateRange) {
        this.control.setValue(this.control.value)
      } else {
        const dateValue = new Date(this.control.value)
        this.control.setValue(dateValue)
      }
    }

    if (this.time) {
      const result = set(new Date(this.control.value), {
        seconds: 0,
      })
      this.control.setValue(result)

      const currentHour = this.control.value ? getHours(new Date(this.control.value)) : new Date().getHours
      const currentMinute = this.control.value ? getMinutes(new Date(this.control.value)) : new Date().getMinutes()
      this.timeForm = this.formBuilder.group({
        inputHour: [String(currentHour).padStart(2, '0'), [Validators.required, Validators.min(0), Validators.max(23)]],
        inputMinute: [
          String(currentMinute).padStart(2, '0'),
          [Validators.required, Validators.min(0), Validators.max(59)],
        ],
      })
      if (this.disabled) {
        this.inputHour.disable()
        this.inputMinute.disable()
      }
    }
  }

  public displayErrors(): string[] | string {
    if (!this.control || !this.control.errors || this.control.pristine) {
      return []
    }
    const r = Object.keys(this.control.errors)
    if (r.includes('matDatepickerParse')) {
      return this.translate.instant('ERROR.FORMAT')
    } else if (r.includes('matDatepickerMin')) {
      const dateForm = format(new Date(this.control.value), this.userService.currentUserDateFormat.format)
      const minDate = format(new Date(this.formElement.minDateValue), this.userService.currentUserDateFormat.format)
      if (isBefore(new Date(dateForm), new Date(minDate))) {
        return `${this.translate.instant('ERROR.MINVALUE')} ${format(
          new Date(this.formElement.minDateValue),
          this.userService.currentUserDateFormat.format
        )}`
      }
    }
    return ''
  }

  public onHourChange(hour: string) {
    if (this.control.value) {
      const result = set(new Date(this.control.value), { hours: +hour })
      this.control.setValue(result)
      if (this.timeForm.invalid) {
        this.control.setErrors({ invalidTime: true })
      } else {
        this.control.setErrors({ invalidTime: null })
        this.control.updateValueAndValidity()
      }
    }
  }

  public onMinuteChange(minute: string) {
    if (this.control.value) {
      const result = set(new Date(this.control.value), { minutes: +minute })
      this.control.setValue(result)
      if (this.timeForm.invalid) {
        this.control.setErrors({ invalidTime: true })
      } else {
        this.control.setErrors({ invalidTime: null })
        this.control.updateValueAndValidity()
      }
    }
  }

  public hourDisplay(hour?: string): string | undefined {
    return hour
  }

  public filterHours(hour: string, input: string | any): boolean {
    return hour.indexOf(input) !== -1
  }

  public sortHours(hours: string[]): string[] {
    return hours.sort((h1: string, h2: string) => h1.localeCompare(h2))
  }

  public filterMinutes(minute: string, input: string | any): boolean {
    return minute.indexOf(input) !== -1
  }

  public sortMinutes(minutes: string[]): string[] {
    return minutes.sort((m1: string, m2: string) => m1.localeCompare(m2))
  }

  onDateChange(newDate: Date | Date[]) {
    this.control.setValue(newDate)
  }

  public convertMinMaxDateAllowed() {
    this.minDateAllowed = this.minDateAllowed ? new Date(this.minDateAllowed) : null
    this.maxDateAllowed = this.maxDateAllowed ? new Date(this.maxDateAllowed) : null
  }
}
