<div class="agroone-snack-bar">
  <div class="agroone-snack-bar-container">
    <div *ngIf="data.icon" class="agroone-snack-bar-container-icon">
      <mat-icon>{{ data.icon }}</mat-icon>
    </div>
    <div class="agroone-snack-bar-container-data">
      <h1 *ngIf="data.title" class="agroone-snack-bar-container-data-title">{{ data.title }}</h1>
      <p class="agroone-snack-bar-container-data-message">{{ data.message }}</p>
      <a *ngIf="data.link" class="agroone-snack-bar-container-data-link" href="" [routerLink]="data.link.route">
        <span>{{ data.link.text }}</span>
      </a>
    </div>
  </div>
</div>
