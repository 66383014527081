import { BehaviorSubject, map, Observable, retry } from 'rxjs'

import { environment } from '@agroone-app/env/environment'
import { LoggerService, MonitoringService } from '@agroone-front/shared'
import { MsSqlPaginatedData, Profil, Technician, UserDto, UserPreferenceDto } from '@agroone/entities'
import { dateToISO8601 } from '@agroone/helpers'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'

import { UserDefaultDateFormat } from './user-date-format.model'
import { DateFormats } from '@agroone/dates'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly allowedProfilsToCreateNotifications: Profil[] = [Profil.MANAGER, Profil.TECHNICIAN, Profil.SCOUT]
  public user: Observable<UserDto>
  public userCanUseNotificationFeature: boolean

  public get currentUser(): UserDto {
    return this.userSubject.getValue()
  }

  public set currentUser(user: UserDto) {
    this.userSubject.next(user)
  }

  public get currentUserDateFormat(): UserDefaultDateFormat {
    return this.defaultDateUserSubject.getValue()
  }

  public get currentUserDateHourFormat(): UserDefaultDateFormat {
    return this.defaultDateHourUserSubject.getValue()
  }

  private readonly defaultDateFormat: DateFormats = DateFormats.DD_MM_YY
  private userSubject: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null)

  private defaultDateUserSubject: BehaviorSubject<UserDefaultDateFormat> = new BehaviorSubject<UserDefaultDateFormat>(
    null
  )

  private defaultDateHourUserSubject: BehaviorSubject<UserDefaultDateFormat> =
    new BehaviorSubject<UserDefaultDateFormat>(null)

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private logger: LoggerService,
    private monitoringService: MonitoringService
  ) {
    this.user = this.userSubject.asObservable()
  }

  public init(): Observable<UserDto> {
    this.user.subscribe((user) => {
      if (user) {
        if (!user.defaultDateFormat) {
          user.defaultDateFormat = this.defaultDateFormat
        }
        this.defaultDateUserSubject.next(new UserDefaultDateFormat(user.defaultDateFormat))
        this.defaultDateHourUserSubject.next(new UserDefaultDateFormat((user.defaultDateFormat + ' HH:mm') as any))

        this.setLanguage(user.language)

        this.monitoringService.identifyUser(user.email)
        this.userCanUseNotificationFeature = this.allowedProfilsToCreateNotifications.includes(this.currentUser.profil)
      }
    })
    return this.getCurrentUser()
  }

  public setLanguage(language: string) {
    this.translate.setDefaultLang(language)
    this.translate.use(language)
    this.dateAdapter.setLocale(language)
  }

  public getCurrentUser(): Observable<UserDto> {
    return this.http.get<UserDto>(`${environment.newApiUrl}/users/me`).pipe(
      retry(1),
      map((user: UserDto) => {
        user.availableRegions?.sort((x, y) => x.localeCompare(y))
        const newUser = new UserDto(user)
        if (user) {
          this.userSubject.next(newUser)
          this.logger.log('User Initialized')
        }
        return newUser
      })
    )
  }

  public getUserByEmail(userEmail: string): Observable<UserDto> {
    const params = { email: userEmail }
    return this.http.get<UserDto>(`${environment.newApiUrl}/users/email`, {
      params,
    })
  }

  public getTechnicians(): Observable<Technician[]> {
    return this.http.get<Technician[]>(`${environment.newApiUrl}/technicians`)
  }

  public getAllUsers(params: {
    pageLength?: number
    page?: number
    noPagination?: boolean
    lite?: boolean
    search?: string
    fetchAvailableRegion?: boolean
  }): Observable<MsSqlPaginatedData<UserDto>> {
    return this.http.get<MsSqlPaginatedData<UserDto>>(`${environment.newApiUrl}/users`, {
      params,
    })
  }

  public updateUserPreference(user: UserPreferenceDto): Observable<UserDto> {
    return this.http.put<UserDto>(`${environment.newApiUrl}/users/me`, user)
  }

  public update(user: UserDto | Partial<UserDto>): Observable<UserDto> {
    if (!user.createdBy) {
      user.updatedBy = this.currentUser.email
      user.updatedDate = dateToISO8601(new Date())
    }
    return this.http.put<UserDto>(`${environment.newApiUrl}/users`, user).pipe(
      map((returnedUser: UserDto) => {
        const newUser = new UserDto(returnedUser)
        return newUser
      })
    )
  }

  public delete(userId: number): Observable<void> {
    return this.http.delete(`${environment.newApiUrl}/users/${userId}`) as Observable<any>
  }
}
