import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { UserService } from './user.service'
import { LoggerService } from '@agroone-front/shared'
import { MessageService } from '../message/services/message.service'
import { TranslateService } from '@ngx-translate/core'
import { PermissionService } from './permission.service'

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private logger: LoggerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private permissionService: PermissionService
  ) {}

  /**
   * Validates that a user exist and checks the current user permissions
   */
  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    if (!this.userService.currentUser) {
      this.logger.warn('Access denied - User not found')
      return false
    }

    if (!next.data.permissions) {
      return true
    }

    if (next.data.permissions) {
      const permissions: [] = next.data.permissions ?? []
      const hasAccess: boolean = this.permissionService.isGranted(permissions)

      if (!hasAccess) {
        this.logger.warn(`Access denied - Permission(s) ${permissions.join(', ')} required`)
        this.messageService.publishMessage(
          this.messageService.createMessage(this.translate.instant('PERMISSION.ACCESS_DENIED'))
        )
      } else {
        this.logger.log(`Access granted - Permission(s) ${permissions.join(', ')} OK`)
      }
      return hasAccess
    }
  }
}
