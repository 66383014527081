import { Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

import { AgroOneSnackBarData } from './models/AgroOneSnackBarData.model'

@Component({
  selector: 'agroone-snack-bar',
  templateUrl: './agroone-snack-bar.component.html',
  styleUrls: ['./agroone-snack-bar.component.sass'],
})
export class AgroOneSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: AgroOneSnackBarData
  ) {}
}
