import { Contact } from './contact'
import { SaveLaboratory } from './save-laboratory'

export class Laboratory {
  id: number | undefined
  name: string | undefined
  types: string[] | undefined
  regions: string[] | undefined
  contacts: Contact[] | undefined

  constructor(laboratory?: Laboratory) {
    if (laboratory?.id) {
      this.id = laboratory?.id
    }
    this.name = laboratory?.name ?? ''
    this.types = laboratory?.types ?? []
    this.regions = laboratory?.regions ?? []
    this.contacts = laboratory?.contacts?.map((c) => new Contact(c as any))
  }
}

export class LaboratoryDAO {
  id?: number
  name: string | undefined

  constructor(laboratory: SaveLaboratory) {
    this.id = laboratory?.id
    this.name = laboratory?.name
  }
}
export class LaboratoryContactDAO {
  laboratoryId: number | undefined
  contactId: number | undefined
}

export class LaboratoryRegionDAO {
  laboratoryId: number | undefined
  regionId: number | undefined
}
