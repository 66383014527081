import { dateToISO8601, ISO8601ToDate } from '@agroone/helpers'

import { AgrooneNutrient } from './agroone-nutrient'
import { Location } from './location'
import { MechanicalWeeding } from './mechanical-weeding'
import { NutrientActivity } from './nutrient'
import { PesticideProductLite, Reentry } from './pesticide'
import { Product } from './product'
import {
  RecommendationFoodSafety,
  RecommendationIrrigation,
  RecommendationOtherOperation,
  RecommendationPesticides,
  RecommendationPlanting,
} from './recommendation'
import { ReportingDAO } from './reporting'
import { SaveIntervention } from './save-intervention'
import {
  WorkOrderFoodSafety,
  WorkOrderIrrigation,
  WorkOrderMechanicalWeeding,
  WorkOrderOtherOperation,
  WorkOrderPesticide,
  WorkOrderPlanting,
} from './work-order'

export class InterventionIrrigation {
  id: number
  completedStartingDate: string
  completedEndingDate: string
  area: number
  amount: number
  areaUnit: string
  amountUnit: string
  nutrients?: AgrooneNutrient[] | NutrientActivity[]

  get completedStartingDateAsDate(): Date {
    return ISO8601ToDate(this.completedStartingDate)
  }

  get completedEndingDateAsDate(): Date {
    return ISO8601ToDate(this.completedEndingDate)
  }

  constructor(
    interventionIrrigation?: Omit<InterventionIrrigation, 'completedStartingDateAsDate' | 'completedEndingDateAsDate'>
  ) {
    this.completedStartingDate = interventionIrrigation?.completedStartingDate ?? dateToISO8601(new Date())
    this.completedEndingDate = interventionIrrigation?.completedEndingDate ?? dateToISO8601(new Date())
    this.area = interventionIrrigation?.area ?? 0
    this.amount = interventionIrrigation?.amount ?? 0
    this.areaUnit = interventionIrrigation?.areaUnit ?? ''
    this.amountUnit = interventionIrrigation?.amountUnit ?? ''
    this.nutrients = interventionIrrigation?.nutrients
  }
}

export class InterventionMechanicalWeeding {
  id: number
  area: string
  completedDate: string

  get completedDateAsDate(): Date {
    return ISO8601ToDate(this.completedDate)
  }

  constructor(recommendationMechanicalWeeding?: Omit<InterventionMechanicalWeeding, 'completedDateAsDate'>) {
    this.area = recommendationMechanicalWeeding?.area
    this.completedDate = recommendationMechanicalWeeding?.completedDate ?? ''
  }
}

export class InterventionOtherOperation {
  id: number
  operationType: string
  area: string
  completedDate: string

  get completedDateAsDate(): Date {
    return ISO8601ToDate(this.completedDate)
  }

  constructor(recommendationOtherOperation?: Omit<InterventionOtherOperation, 'completedDateAsDate'>) {
    this.operationType = recommendationOtherOperation?.operationType
    this.area = recommendationOtherOperation?.area
    this.completedDate = recommendationOtherOperation?.completedDate ?? ''
  }
}

export class InterventionForeignMaterial {
  id: number
  type?: string
  subType?: string
  area?: string
  completedDate: string

  get completedDateAsDate(): Date {
    return ISO8601ToDate(this.completedDate)
  }

  constructor(interventionForeignMaterial?: Omit<InterventionForeignMaterial, 'completedDateAsDate'>) {
    this.completedDate = interventionForeignMaterial?.completedDate ?? ''
    this.type = interventionForeignMaterial?.type ?? ''
    this.subType = interventionForeignMaterial?.subType ?? ''
    this.area = interventionForeignMaterial?.area ?? ''
  }
}

export class InterventionFoodSafety {
  id?: number
  location?: Location
  bufferZone?: number
  bufferZoneUnit?: string
  completedDate?: string

  get completedDateAsDate(): Date {
    return ISO8601ToDate(this.completedDate)
  }

  constructor(interventionFoodSafety?: Omit<InterventionFoodSafety, 'completedDateAsDate'>) {
    this.completedDate = interventionFoodSafety?.completedDate ?? dateToISO8601(new Date())
    if (interventionFoodSafety?.location) {
      this.location = interventionFoodSafety?.location
    }
    if (interventionFoodSafety?.bufferZone) {
      this.bufferZone = +interventionFoodSafety?.bufferZone
    }
    if (interventionFoodSafety?.bufferZoneUnit) {
      this.bufferZoneUnit = interventionFoodSafety?.bufferZoneUnit
    }
  }
}

export class InterventionPesticidesProduct {
  pesticideId: string
  activeIngredientsNames: string[]
  identificationNumber: string
  name: string
  rate: number
  rateUnit: string
  rateAreaUnit: string
  reentries?: Reentry[]
}

export class InterventionPesticidesProductDAO {
  pesticideProductId: number
  interventionPesticideId: number
  name: string
  rate: number
  rateUnit: string
  rateAreaUnit: string
}

export class InterventionPesticide {
  id: number
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  products: InterventionPesticidesProduct[]
  weatherConditions: string
  completedDate: string
  windSpeed: number
  windSpeedUnit: string
  applicationEquipment: string
  area?: number
  areaUnit?: string
  windDirection?: string
  temperature?: number
  temperatureUnit?: string
  applicatorName?: string
  targetPest?: string
}

export class InterventionPesticideDAO {
  id?: number
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  weatherConditions: string
  completedDate: string
  windSpeed?: number
  windSpeedUnit?: string
  applicationEquipment?: string
  area: number
  areaUnit: string
  windDirection?: string
  temperature?: number
  temperatureUnit?: string
  applicatorName?: string
  targetPest?: string

  constructor(interventionPesticide: InterventionPesticideDAO) {
    if (interventionPesticide?.id) {
      this.id = interventionPesticide?.id
    }
    this.waterAmount = interventionPesticide?.waterAmount ?? null
    this.waterUnit = interventionPesticide?.waterUnit ?? null
    this.waterAreaUnit = interventionPesticide?.waterAreaUnit ?? null
    this.weatherConditions = interventionPesticide?.weatherConditions ?? null
    this.completedDate = interventionPesticide?.completedDate ?? null
    this.windSpeed = interventionPesticide?.windSpeed ?? null
    this.windSpeedUnit = interventionPesticide?.windSpeedUnit ?? null
    this.applicationEquipment = interventionPesticide?.applicationEquipment ?? null
    this.area = interventionPesticide?.area ?? 0
    this.areaUnit = interventionPesticide?.areaUnit ?? ''
    this.windDirection = interventionPesticide?.windDirection ?? null
    this.temperature = interventionPesticide?.temperature ?? null
    this.temperatureUnit = interventionPesticide?.temperatureUnit ?? null
    this.applicatorName = interventionPesticide?.applicatorName ?? null
    this.targetPest = interventionPesticide?.targetPest ?? null
  }
}

export class InterventionPesticideProductsDAO {
  id?: number
  rate: number
  rateUnit: string
  rateAreaUnit: string
  interventionPesticideId: number
  pesticideProductId: number

  constructor(interventionPesticide: InterventionPesticideProductsDAO) {
    if (interventionPesticide?.id) {
      this.id = interventionPesticide?.id
    }
    this.rate = interventionPesticide?.rate ?? null
    this.rateUnit = interventionPesticide?.rateUnit ?? null
    this.rateAreaUnit = interventionPesticide?.rateAreaUnit ?? null
    this.interventionPesticideId = interventionPesticide?.interventionPesticideId ?? null
    this.pesticideProductId = interventionPesticide?.pesticideProductId ?? null
  }
}

export class InterventionPesticidesReentriesDAO {
  id?: number
  reentryInterval: number
  reentryUnit: string
  interventionMinValue: number
  interventionMinUnit: string
  applicationNumber: number
  fieldType: string

  constructor(interventionPesticideReentries?: InterventionPesticidesReentriesDAO) {
    if (interventionPesticideReentries?.id) {
      this.id = interventionPesticideReentries?.id
    }
    this.reentryInterval = interventionPesticideReentries?.reentryInterval
    this.reentryUnit = interventionPesticideReentries?.reentryUnit
    this.interventionMinUnit = interventionPesticideReentries?.interventionMinUnit
    this.interventionMinValue = interventionPesticideReentries?.interventionMinValue
    this.applicationNumber = interventionPesticideReentries?.applicationNumber
    this.fieldType = interventionPesticideReentries?.fieldType
  }
}

export class InterventionRecommendation {
  id: number
  planting?: RecommendationPlanting
  irrigation?: RecommendationIrrigation
  mechanicalWeeding?: MechanicalWeeding
  otherOperation?: RecommendationOtherOperation
  foodSafety?: RecommendationFoodSafety
  pesticides?: RecommendationPesticides
}

export class InterventionCrop {
  id: number
  name: string
  shortName: string
}

export class InterventionWorkOrder {
  id: number
  planting?: WorkOrderPlanting
  irrigation?: WorkOrderIrrigation
  mechanicalWeeding?: WorkOrderMechanicalWeeding
  otherOperation?: WorkOrderOtherOperation
  foodSafety?: WorkOrderFoodSafety
  pesticides?: WorkOrderPesticide
}

export class InterventionNutrient {
  id: number
  targetDate: string
  area: number
  areaUnit: string
  amount: number
  amountUnit: string
  nutrients?: AgrooneNutrient[] | NutrientActivity[]
  nameProduct?: string

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(interventionNutrient?: Omit<InterventionNutrient, 'targetDateAsDate'>) {
    this.targetDate = interventionNutrient?.targetDate ?? dateToISO8601(new Date())

    this.area = interventionNutrient?.area ?? 0
    this.areaUnit = interventionNutrient?.areaUnit ?? ''
    this.amount = interventionNutrient?.amount ?? 0
    this.amountUnit = interventionNutrient?.amountUnit ?? ''
    this.nutrients = interventionNutrient?.nutrients
  }
}

export class Intervention {
  static readonly className: string = 'Intervention'

  id: number
  completedBy: string
  date: string
  crops: InterventionCrop[]
  type: string
  subType: string
  region: string
  regionId: string
  note?: string
  attachmentPath?: string
  picturesPath?: string[]
  recommendation?: InterventionRecommendation
  workOrder?: InterventionWorkOrder
  nutrient?: InterventionNutrient
  foodSafety?: InterventionFoodSafety
  mechanicalWeeding?: InterventionMechanicalWeeding
  otherOperation?: InterventionOtherOperation
  pesticides?: InterventionPesticide | InterventionPesticides
  irrigation?: InterventionIrrigation
  foreignMaterial?: InterventionForeignMaterial
  disablingDate?: string
  externalId?: number
  externalClientId?: number

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  constructor(intervention?: Omit<Intervention, 'dateAsDate'>) {
    this.id = intervention?.id
    this.date = intervention?.date
    this.completedBy = intervention?.completedBy
    this.type = intervention?.type
    this.subType = intervention?.subType
    this.crops = intervention?.crops ?? []
    this.region = intervention?.region
    this.regionId = intervention?.regionId ?? null
    this.note = intervention?.note
    this.attachmentPath = intervention?.attachmentPath
    this.picturesPath = intervention?.picturesPath ?? []
    this.disablingDate = intervention?.disablingDate ?? null
    if (intervention?.id) {
      this.id = intervention?.id
    }
    if (intervention?.pesticides) {
      this.pesticides = intervention?.pesticides
    }
    if (intervention?.irrigation) {
      this.irrigation = new InterventionIrrigation(intervention?.irrigation)
    }
    if (intervention?.mechanicalWeeding) {
      this.mechanicalWeeding = new InterventionMechanicalWeeding(intervention?.mechanicalWeeding)
    }
    if (intervention?.otherOperation) {
      this.otherOperation = new InterventionOtherOperation(intervention?.otherOperation)
    }
    if (intervention?.foreignMaterial) {
      this.foreignMaterial = new InterventionForeignMaterial(intervention?.foreignMaterial)
    }
    if (intervention?.foodSafety) {
      this.foodSafety = new InterventionFoodSafety(intervention?.foodSafety)
    }
    if (intervention?.nutrient) {
      this.nutrient = new InterventionNutrient(intervention?.nutrient)
    }

    if (intervention?.recommendation) {
      this.recommendation = intervention.recommendation
    }
    if (intervention?.workOrder) {
      this.workOrder = intervention.workOrder
    }
    this.externalId = intervention?.externalId
    this.externalClientId = intervention?.externalClientId
  }
}

export class InterventionNutrientDAO {
  id?: number
  targetDate: string
  area: number
  areaUnit: string
  amount: number
  amountUnit: string
  nameProduct?: string

  constructor(interventionNutrient?: InterventionNutrientDAO) {
    if (interventionNutrient?.id) {
      this.id = interventionNutrient?.id
    }
    this.targetDate = interventionNutrient?.targetDate
    this.area = interventionNutrient?.area
    this.areaUnit = interventionNutrient?.areaUnit
    this.amount = interventionNutrient?.amount
    this.amountUnit = interventionNutrient?.amountUnit
    this.nameProduct = interventionNutrient?.nameProduct ?? null
  }
}

export class InterventionDAO extends ReportingDAO {
  id?: number
  completedBy: string
  date: string
  type: string
  subType: string
  note?: string
  attachmentPath?: string
  disablingDate?: string
  externalId?: number
  externalClientId?: number
  workOrderId?: number
  foodSafetyId?: number
  irrigationId?: number
  mechanicalWeedingId?: number
  otherOperationId?: number
  foreignMaterialId?: number
  pesticideId?: number
  regionId: number
  recommendationId?: number
  nutrientId?: number

  constructor(intervention?: SaveIntervention) {
    super(intervention)
  }
}

export class InterventionRequest {
  id?: number
  date: string
  completedBy: string
  type: string
  subType: string
  note?: string
  disablingDate?: string
  attachmentPath?: string
  externalId?: string
  externalClientId?: number
  region: string
  fsId?: number
  fsBufferZone?: number
  fsBufferZoneUnit?: string
  fsCompletedDate?: string
  fsLatitude?: string
  fsLongitude?: string
  fsPrecision?: string
  fsGeohash?: string
  fmId?: number
  fmType?: string
  fmSubType?: string
  fmArea?: string
  fmCompletedDate: string
  mwId?: number
  mwArea?: string
  mwCompletedDate?: string
  ooId?: number
  ooOperationType?: string
  ooArea?: string
  ooTargetDate?: string
  iPId?: number
  iPWaterAmount?: number
  iPWaterUnit?: string
  iPWaterAreaUnit?: string
  iPWeatherConditions?: string
  iPCompletedDate?: string
  iPWindSpeed?: number
  iPWindSpeedUnit?: string
  iPApplicationEquipment?: string
  iPArea?: number
  iPAreaUnit?: string
  iPWindDirection?: string
  iPTemperature?: number
  iPTemperatureUnit?: string
  iPApplicatorName?: string
  iPTargetPest?: string
  pIds?: string
  pIdentificationNumbers?: string
  pNames?: string
  pPesticideTypes?: string
  pRates?: string
  pRateUnits?: string
  pRateAreaUnits?: string
  iNId?: number
  iNArea?: number
  iNAreaUnit?: string
  iNAmount?: number
  iNAmountUnit?: string
  iNTargetDate?: string
  iNNameProduct?: string
  iNIds?: string
  iNKeys?: string
  iNTypes?: string
  iNNutrientIds?: string
  iNValues?: string
  iId?: number
  iStartingDate?: string
  iEndingDate?: string
  iArea?: number
  iAmount?: number
  iAreaUnit?: string
  iAmountUnit?: string
  nIds?: string
  nKeys?: string
  nTypes?: string
  nValues?: string
  nNutrientIds?: string
  cropIds?: string
  cropNames?: string
  cropShortNames?: string
  cropIntendedAreas?: string
  cropAreaUnits?: string
  cropPlantedAreas?: string
  cropPlantedDates?: string
  cropFieldIds?: string
  cropLatitudes?: string
  cropLongitudes?: string
  cropPrecisions?: string
  cropGeohashs?: string
  cropBoundaries?: string
  picturePaths?: string
}

export class InterventionPesticides {
  completedDate: string
  weatherConditions: string
  products: Product[]
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  windSpeed?: number
  windSpeedUnit?: string
  applicationEquipment?: string
  area?: number
  areaUnit?: string
  windDirection?: string
  temperature?: number
  temperatureUnit?: string
  applicatorName?: string
  targetPest?: string

  get completedDateAsDate(): Date {
    return ISO8601ToDate(this.completedDate)
  }

  constructor(interventionPesticides?: Omit<InterventionPesticides, 'completedDateAsDate'>) {
    this.completedDate = interventionPesticides?.completedDate ?? ''
    this.weatherConditions = interventionPesticides?.weatherConditions ?? ''
    this.waterAmount = +(interventionPesticides?.waterAmount ?? 0)
    this.waterUnit = interventionPesticides?.waterUnit ?? ''
    this.waterAreaUnit = interventionPesticides?.waterAreaUnit ?? ''
    this.products = interventionPesticides?.products.map((p) => new Product(p))
    if (interventionPesticides?.windSpeed) {
      this.windSpeed = interventionPesticides.windSpeed
    }
    if (interventionPesticides?.windSpeedUnit) {
      this.windSpeedUnit = interventionPesticides.windSpeedUnit
    }
    if (interventionPesticides?.applicationEquipment) {
      this.applicationEquipment = interventionPesticides.applicationEquipment
    }
    this.area = interventionPesticides?.area ?? 0
    this.areaUnit = interventionPesticides?.areaUnit ?? ''
    this.windDirection = interventionPesticides?.windDirection ?? ''
    this.temperature = interventionPesticides?.temperature
    this.temperatureUnit = interventionPesticides?.temperatureUnit
    this.applicatorName = interventionPesticides?.applicatorName ?? ''
    this.targetPest = interventionPesticides?.targetPest ?? ''
  }
}
