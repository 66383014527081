import { Component } from '@angular/core'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-new-version-popup',
  templateUrl: './new-version-popup.component.html',
  styleUrls: ['./new-version-popup.component.sass'],
  imports: [MatDialogModule, TranslateModule],
  standalone: true,
})
export class NewVersionPopupComponent {
  reload() {
    window.location.reload()
  }
}
