import { UserService } from '@agroone-app/core/user/user.service'
import { environment } from '@agroone-app/env/environment'
import { NetworkService } from '@agroone-front/shared'
import { MsSqlPaginatedData, Region } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, mergeMap, Observable, of, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  public get allRegion(): Region[] {
    return this.regionsSubject.getValue()
  }

  private regionName: string
  private region: Region
  private regionsSubject: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>(null)
  /**
   * Return the region of the current user
   */

  private _setActiveRegion() {
    this.region = this.allRegion?.find((r) => r.name === this.userService.currentUser.regionName)
    this.regionName = this.region?.name
  }

  get activeRegion(): Observable<Region> {
    if (this.region && this.regionName === this.userService.currentUser?.regionName) {
      return of(this.region)
    } else {
      if (this.userService.currentUser) {
        this._setActiveRegion()
        return of(this.region)
      } else {
        // Should never happen
        const err = new Error('User not found')
        return throwError(() => err)
      }
    }
  }

  get currentActiveRegion(): Region {
    if (this.region && this.regionName === this.userService.currentUser?.regionName) {
      return this.region
    } else if (this.userService.currentUser?.regionName) {
      this._setActiveRegion()
      return this.region
    }
    return undefined
  }

  constructor(private http: HttpClient, private userService: UserService, protected networkService: NetworkService) {
    // Watch for user changes to update activeRegion
    this.userService.user.pipe(mergeMap(() => this.activeRegion.pipe(catchError(() => of())))).subscribe()
  }

  init(): Observable<Region[]> {
    return this.getAll()
  }

  public get(id: number): Observable<Region> {
    return this.http
      .get<Region>(`${environment.newApiUrl}/regions/${id}`)
      .pipe(map((region: Region) => new Region(region)))
  }

  public getAll(): Observable<Region[]> {
    return this.http.get<MsSqlPaginatedData<Region>>(`${environment.newApiUrl}/regions`).pipe(
      map((regions) => {
        const dataRegion = regions.data.map((region) => new Region(region))
        dataRegion.sort((x, y) => x.name.localeCompare(y.name))
        this.regionsSubject.next(dataRegion)
        return dataRegion
      })
    )
  }
}
