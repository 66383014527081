import { Injectable, inject } from '@angular/core'
import { UserDto, UserPermissions } from '@agroone/entities'
import { UserService } from './user.service'
import { OFFLINE_PERMISSIONS } from '@agroone-app/shared/user/config/offline-permissions-config'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ConnectionService } from '@agroone-front/shared'

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userService = inject(UserService)
  private connectionService = inject(ConnectionService)

  /**
   * Validates that the user has the given permission(s)
   */
  public hasPermission(...permissions: UserPermissions[]): boolean {
    const user: UserDto = this.userService.currentUser
    if (user) {
      return permissions.every((permission) => Boolean(user.permissions.find((p) => p === permission)))
    }
    return false
  }

  /**
   * Return true if user has at least one
   * permission
   *
   * @param permissions {UserPermissions[]}
   * @returns {boolean}
   */
  public isGranted(permissions: UserPermissions[], isConnected: boolean = true): boolean {
    if (!isConnected || !this.connectionService.connected) {
      return this.canOffline(permissions)
    }
    const user: UserDto = this.userService.currentUser
    let permissionCount: number = 0
    if (permissions?.length > 0) {
      permissionCount = permissions.filter((permission) => user.permissions.includes(permission))?.length
    }

    return permissionCount > 0
  }

  public canOffline(permissions: UserPermissions[]): boolean {
    return permissions.filter((permission) => OFFLINE_PERMISSIONS.includes(permission))?.length > 0
  }
}
