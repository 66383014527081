<div class="crop-filters">
  <mat-dialog-content class="crop-filters-content">
    <form [formGroup]="form">
      <!-- CropType -->
      <ng-container *ngIf="cropTypes">
        <mat-label>{{ 'CROP.FILTERS.CROP_TYPE' | translate }}</mat-label>
        <app-autocomplete-select [list]="cropTypes" [formCtrl]="cropType" optional="true"> </app-autocomplete-select>
      </ng-container>

      <!-- GrowerName -->
      <ng-container *ngIf="growers">
        <mat-label>{{ 'CROP.FILTERS.GROWER_NAME' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="growers"
          [formCtrl]="grower"
          [displayFn]="growerDisplay"
          [filterFn]="filterGrower"
          optional="true"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- Technicians -->
      <div class="crop-filters-daterange">
        <ng-container *ngIf="technicians">
          <mat-label>{{ 'FIELD.CROP.TECHNICIAN' | translate }}</mat-label>
          <app-autocomplete-select
            [list]="technicians"
            [formCtrl]="technician"
            [filterFn]="filterTechnician"
            [displayFn]="technicianDisplay"
            [sortFn]="sortTechnician"
            optional="true"
          ></app-autocomplete-select>
        </ng-container>

        <!-- Planted date -->
        <div *ngIf="selectedReport === weeklySowing" class="crop-filters-daterange-container">
          <mat-label>{{ 'CROP.FILTERS.DATE' | translate }}</mat-label>
          <app-datetimepicker
            [formElement]="plantedDateElement"
            icon="calendar_harvest"
            data-testid="crops-filter-plantedDate"
            [isDateRange]="true"
            [control]="plantedDateRange"
            [cancel]="true"
          >
          </app-datetimepicker>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="crop-filters-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
